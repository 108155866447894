import React from 'react';

export default function RationTableCreate(props) {
  return (        
          <>
            <tr>
                <td><span>{props.ration.name}</span></td>
                <td>{props.ration.protein}</td>
                <td>{props.ration.fat}</td>
                <td>{props.ration.carbs}</td>
                <td>{props.ration.kal}</td>
                <td><span>{props.ration.wt}</span></td>
            </tr>
          </>    
  )
}



