import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export default function TabledataHeader() {
    return (
        <>
           <Container>
               <Row className="justify-content-md-center" style={{padding: '74px 0'}}>
                   <Col sm="1">
                       <a href='#'><svg width="66" height="61" viewBox="0 0 66 61" fill="none" xmlns="http://www.w3.org/2000/svg" class="header__logo_img"><path d="M21.5768 60.9898L7.41797 52.1066C18.1409 32.5553 38.1688 18.6328 61.7107 16.04L65.6522 27.8911C46.2531 31.6209 30.0598 44.1176 21.5809 60.9878L21.5768 60.9898Z" fill="#7DB360"></path><path d="M26.4538 0L24.666 20.7544L3.92502 15.9899L0 27.8049L14.389 33.7404C17.3564 30.818 20.6754 28.2567 24.2735 26.1124C29.1993 23.1607 33.4819 21.2116 40.7894 18.7692L39.1742 0H26.4538Z" fill="#E04E39"></path><path d="M46.9689 37.5063C42.4356 40.2375 37.9886 45.5254 36.4062 49.3073L36.4104 49.2993L46.7264 60.9999L57.0116 53.7168L46.9689 37.5063Z" fill="#E04E39"></path></svg></a>
                   </Col>
                   <Col sm="auto">
                       <h1 className='header-title'>Сервис «Рекомендации»</h1>
                   </Col>
               </Row>
            </Container>                       
        </>
    )
}