import React from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';


export default function RationOneHeader() {
    return (
        <>
           <Container>
                <div className='container-fluid'>
                    <Row>
                        <Col md={6}>
                            <h2 className='menu-recomendation__haed_title'>Рекомендованный рацион <br/> питания:<span>День 1</span></h2>                       
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <ListGroup horizontal className='menu-recomendation__haed_data'>
                                    <ListGroup.Item className='menu-recomendation__haed_data-item' style={{color: '#e04e39'}}>Б 81 г</ListGroup.Item>
                                    <ListGroup.Item className='menu-recomendation__haed_data-item' style={{color: '#ffb931'}}>Ж 84 г</ListGroup.Item>
                                    <ListGroup.Item className='menu-recomendation__haed_data-item' style={{color: '#7db360'}}>У 357 г</ListGroup.Item>
                                    <ListGroup.Item className='menu-recomendation__haed_data-item' style={{color: '#000'}}>2505 Ккал*</ListGroup.Item>
                            </ListGroup>
                            <p className='menu-recomendation__haed_data-text'>*белки, жиры, углеводы и килокалории в сутки</p>
                        </Col>
                    </Row>
                </div>    
           </Container>                      
        </>
    )
}
