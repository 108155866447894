import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export default function RationTableDesc() {
    return (
        <>
           <Container>
               <Row style={{maxWidth: '1033px', margin: '0 auto 30px'}}>
                   <Col>
                       <p className='ration-table-desc-text'>
                            Рекомендации по питанию подготовлены на основе полученных 
                            данных о Вашем теле, наличии / отсутствии аллергических реакций, 
                            Вашей физической активности. Носят рекомендательный характер. Для получения 
                            более детальных рекомендаций, Вам необходимо обратиться к специалисту (врачу – диетологу).
                       </p>
                   </Col>
               </Row>
            </Container>                       
        </>
    )
}