import React from 'react';
import './App.css';
import RationRoute from './Components/RationNavigate/RationRoute';

function App() {
  return (
    <div className="App">
      <RationRoute/>
    </div>
  );
}

export default App;
