import React from 'react';
import { Container, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function RationLinkTwo() {
  return (        
          <>
            <Container>
                <div className='container-fluid'>
                    <Row>                    
                        <Nav className='m-auto recept-btn'>
                            <Link to='/'>Назад</Link>
                        </Nav>                    
                    </Row>
                </div>
            </Container> 
          </>    
  )
}