import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import RationTableCreate from './RationTableCreate';
import RationTableProgressCreate from './RationTableProgress/RationTableProgressCreate';


export default class RationTableFour extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            rpogressrow: [                 
                {precentprotein: '1', precentproteintwo: '30', labelprotein: 'Б 1 г'},
                {precentfat: '1', precentfattwo: '30', labelfat: 'Ж 1 г'},
                {precentcarbs: '32', precentcarbstwo: '40', labelcarbs: 'У 32 г'}
            ],
            tablerow: [       
                        { name: 'Вафли с фруктовыми начинками', protein: 'Б 1 г', fat: 'Ж 1 г', carbs: 'У 32 г', kal: '136.8 Ккал', wt: '40 г.' }      
                ]
            }
        };
    render() {         
        return (
            <>
                <Container>
                    <div className='container-fluid'>
                        <Row>
                            <Col md={2}>
                                <h3 className='menu_data-title' style={{color: '#e04e39'}}>Полдник</h3>
                                <p className='menu_data-text'>136 дневной нормы Ккал</p>
                                <div>
                                    { this.state.rpogressrow.map(rpogress => {
                                                return (         
                                                                                
                                                <RationTableProgressCreate                                
                                                    rpogress={rpogress} key={rpogress.id}                                                                     
                                                />                                                        
                                                )
                                    }) } 
                                </div>
                            </Col>
                            <Col md={10}>
                                <Table striped bordered hover className='ration-table-one'>
                                    <tbody>
                                        { this.state.tablerow.map(ration => {
                                            return (         
                                                                            
                                            <RationTableCreate                                
                                                ration={ration} key={ration.id}                                                                     
                                            />                                                        
                                            )
                                        }) } 
                                    </tbody>
                                </Table>                                                       
                            </Col>
                        </Row>
                    </div>    
                </Container>                       
            </>
        )
    }
}

                                  