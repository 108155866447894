import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import RationTableCreate from './RationTableCreate';
import RationTableProgressCreate from './RationTableProgress/RationTableProgressCreate';

export default class RationTableFour extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            rpogressrow: [                 
                {precentprotein: '23', precentproteintwo: '30', labelprotein: 'Б 23 г'},
                {precentfat: '22', precentfattwo: '30', labelfat: 'Ж 22 г'},
                {precentcarbs: '75', precentcarbstwo: '40', labelcarbs: 'У 75 г'}
            ],
            tablerow: [       
                {name: 'Сырный суп с копченой колбасой', protein: 'Б 22 г', fat: 'Ж 18 г', carbs: 'У 31 г', kal: '419.5 Ккал', wt: '250 г.'}, 
                {name: 'Картофель вареный с зеленью', protein: 'Б 1 г', fat: 'Ж 4 г', carbs: 'У 34 г', kal: '208.3 Ккал', wt: '171 мл.'}, 
                {name: 'Компот из сухофруктов', protein: 'Б 0 г', fat: 'Ж 0 г', carbs: 'У 10 г', kal: '41.3 Ккал', wt: '100 г.'}           
            ]
        }
    }; 
        render() {    
    return (
        <>
            <Container>
                <div className='container-fluid'>
                    <Row>
                        <Col md={2}>
                            <h3 className='menu_data-title' style={{color: '#ed8a15'}}>Обед</h3>
                            <p className='menu_data-text'>631 дневной нормы Ккал</p>
                            <div>
                                { this.state.rpogressrow.map(rpogress => {
                                            return (         
                                                                            
                                            <RationTableProgressCreate                                
                                                rpogress={rpogress} key={rpogress.id}                                                                     
                                            />                                                        
                                            )
                                }) }     
                            </div>
                        </Col>
                        <Col md={10}>
                            <Table striped bordered hover className='ration-table-one'>
                                <tbody>
                                        { this.state.tablerow.map(ration => {
                                            return (         
                                                                            
                                            <RationTableCreate                                
                                                ration={ration} key={ration.id}                                                                     
                                            />                                                        
                                            )
                                        }) } 
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>    
            </Container>                       
        </>
    )
  }
}

                         
