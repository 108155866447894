import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import RationTableCreate from './RationTableCreate';
import RationTableProgressCreate from './RationTableProgress/RationTableProgressCreate';

export default class RationTableFour extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            rpogressrow: [                 
                {precentprotein: '18', precentproteintwo: '30', labelprotein: 'Б 18 г'},
                {precentfat: '15', precentfattwo: '30', labelfat: 'Ж 15 г'},
                {precentcarbs: '88', precentcarbstwo: '40', labelcarbs: 'У 88 г'}
            ],
            tablerow: [       
                 {name: 'Картофель запеченный', protein: 'Б 8 г', fat: 'Ж 6 г', carbs: 'У 69 г', kal: '374 Ккал', wt: '300 мл.'}, 
                 {name: 'Салат хабаровский', protein: 'Б 10 г', fat: 'Ж 9 г', carbs: 'У 12 г', kal: '208.3 Ккал', wt: '170 мл.'}, 
                 {name: 'Грейпфрут', protein: 'Б 0 г', fat: 'Ж 0 г', carbs: 'У 7 г', kal: '35 Ккал', wt: '100 мл.'}, 
                 {name: 'Чай зелёный без сахара', protein: 'Б 0 г', fat: 'Ж 0 г', carbs: 'У 0 г', kal: '0 Ккал', wt: '150 мл.' }           
            ]
        }
    }; 
    render() {
        return (
            <>
                <Container>
                    <div className='container-fluid'>
                        <Row>
                            <Col md={2}>
                                <h3 className='menu_data-title' style={{color: '#c23e2b'}}>Ужин</h3>
                                <p className='menu_data-text'>617 дневной нормы Ккал</p>
                                <div>
                                    { this.state.rpogressrow.map(rpogress => {
                                                return (         
                                                                                
                                                <RationTableProgressCreate                                
                                                    rpogress={rpogress} key={rpogress.id}                                                                     
                                                />                                                        
                                                )
                                    }) }                               
                                </div>
                            </Col>
                            <Col md={10}>
                                <Table striped bordered hover className='ration-table-one'>
                                    <tbody>
                                        { this.state.tablerow.map(ration => {
                                            return (         
                                                                            
                                            <RationTableCreate                                
                                                ration={ration} key={ration.id}                                                                     
                                            />                                                        
                                            )
                                        }) } 
                                    </tbody>
                                </Table>                       
                            </Col>
                        </Row>
                    </div>    
                </Container>                       
            </>
        )
    
    }
}

 
  