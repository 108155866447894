import React from 'react';

export default function TabledataCreate(props) {
  return (        
          <>
            <tr>
                <td>{props.data.datas}</td>
                <td style={{color: '#85be3e'}}>{props.data.datasdata}</td>
                <td style={{textTranseorm: 'uppercase'}}>{props.data.datasmeta}</td>
                <td colSpan="2" style={{color: '#e04e39'}}>{props.data.datasmetadata}</td>
                <td style={{textAlign: 'right'}}>{props.data.datedate}</td>
            </tr>           
          </>    
  )
}

 