import React from 'react';
import { Col, Container,Row } from 'react-bootstrap';
import RationArticles from './RationArticles.json';

export default function RationRecept() {
      
    return (
        <>
            <Container>
                <div className='container-fluid'>

                    <Row>
                        <div style={{marginBottom: '50px'}}>
                            <Col>
                                <h2 classNameclassName='recept-title'>
                                    Рецепты приготовления рекомендованных блюд:
                                </h2>
                                <p className='recept-subtitle'>
                                    Вы можете корректировать рекомендованные рецепты приготовления блюд 
                                    в зависимости от Ваших вкусовых предпочтений.
                                </p>
                            </Col>
                        </div>    
                    </Row>                
                
                    <ul style={{listStyle: 'none', padding: '0'}}>
                        
                        {RationArticles.map(item =>    
                            <li style={{marginBottom: '24px'}}>
                                <Row>
                                    <Col>
                                        <h3 className='recept-title-menu'>{item.title}</h3>                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                        <p className='recept-text-menu'>
                                            {item.ingridients}
                                        </p>
                                    </Col>
                                    <Col lg={9}>
                                        <p className='recept-description-menu'>
                                            {item.description}
                                        </p>
                                    </Col>
                                </Row>
                            </li>
                        )}
                        
                    </ul>
               
                </div>
            </Container>                         
        </>
    )
}


