import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageOne from '../../Pages/PageOne';
import PageTwo from '../../Pages/PageTwo';

export default function RationRoute() {
  return (        
          <>            
            <Routes>                    
                <Route exact path="/" element={<PageOne />} />
                <Route exact path="PageTwo" element={<PageTwo />} />                    
            </Routes>
          </>    
  )
}
