import React from 'react';
import { ProgressBar } from 'react-bootstrap';

export default function RationTableProgressCreate(props) {
  return (        
          <>
            <ProgressBar>

                <ProgressBar variant="danger" now={props.rpogress.precentprotein}  key={1} />
                <ProgressBar variant="white" now={props.rpogress.precentproteintwo} key={2} label={<span>{props.rpogress.labelprotein}</span>} />
            
               <ProgressBar variant="warning" now={props.rpogress.precentfat}  key={1} /> 
               <ProgressBar variant="white" now={props.rpogress.precentfattwo} key={2} label={<span>{props.rpogress.labelfat}</span>} />

               <ProgressBar variant="success" now={props.rpogress.precentcarbs}  key={1} />
               <ProgressBar variant="white" now={props.rpogress.precentcarbstwo} key={2} label={<span>{props.rpogress.labelcarbs}</span>} />

           </ProgressBar>
          </>    
  )
}

