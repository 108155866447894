import React from 'react';
import RationOneHeader from '../Components/RationHeader/RationOneHeader';
import RationTableOne from '../Components/RationTable/RationTableOne';
import RationTableTwo from '../Components/RationTable/RationTableTwo';
import RationTableThree from '../Components/RationTable/RationTableThree';
import Tabledata from '../Components/RationHeader/TableData/Tabledata';
import TabledataHeader from '../Components/RationHeader/TabledataHeader';
import RationDesc from '../Components/RationRecept/RationDesc';
import RationRecept from '../Components/RationRecept/RationRecept';
import RationLinkTwo from '../Components/RationNavigate/RationLinks/RationLinkTwo';

function PageTwo() {
  return (
    <div>
      <TabledataHeader/>
      <Tabledata/>
      <RationOneHeader/>
      <RationTableOne/>
      <RationTableTwo/>
      <RationTableThree/>      
      <RationDesc/>
      <RationRecept/>
      <RationLinkTwo/>
    </div>
  );
}

export default PageTwo;