import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import TabledataCreate from './TabledataCreate';

export default class Tabledata extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
                tabledatarow: [       
                     {datas: 'Рост', datasdata: '175.0 см', datasmeta: 'УРОВЕНЬ БАЗАЛЬНОГО МЕТАБОЛИЗМА', datasmetadata: '1648', datedate: '02.12.2021 11:00'}, 
                     {datas: 'Вес', datasdata: '75.8 кг', datasmeta: 'СОДЕРЖАНИЕ ЖИРА В ТЕЛЕ', datasmetadata: '22.0%', datedate: 'Мужчина'},
                     {datas: 'Возраст', datasdata: '35 г', datasmeta: 'ВИСЦЕРАЛЬНЫЙ ЖИР', datasmetadata: '90.2'}           
                ]
            }
        }; 
        render() {    
    return (
        <>
            <Container>
                <div className='container-fluid'>
                    <Row>
                        <Col>
                            <Table style={{background: '#f7f7f7'}} className='table-data'>
                                <tbody>
                                        { this.state.tabledatarow.map(data => {
                                            return (         
                                                                            
                                            <TabledataCreate                                
                                                data={data} key={data.id}                                                                     
                                            />                                                        
                                            )
                                        }) } 
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>    
            </Container>                     
        </>
    )
  }  
}

