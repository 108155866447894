import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import RationTableCreate from './RationTableCreate';
import RationTableProgressCreate from './RationTableProgress/RationTableProgressCreate';

export default class RationTableFour extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            rpogressrow: [                 
                {precentprotein: '26', precentproteintwo: '30', labelprotein: 'Б 26 г'},
                {precentfat: '24', precentfattwo: '30', labelfat: 'Ж 24 г'},
                {precentcarbs: '102', precentcarbstwo: '40', labelcarbs: 'У 102 г'}
            ],
            tablerow: [       
                {name: 'Овсяноблин с помидорами', protein: 'Б 22 г', fat: 'Ж 20 г', carbs: 'У 33 г', kal: '408 Ккал', wt: '200 г.'}, 
                {name: 'Какао с молоком', protein: 'Б 4 г', fat: 'Ж 4 г', carbs: 'У 25 г', kal: '154.2 Ккал', wt: '150 мл.'}, 
                {name: 'Зефир', protein: 'Б 0 г', fat: 'Ж 0 г', carbs: 'У 31 г', kal: '119.6 Ккал', wt: '40 г.'}, 
                {name: 'Кофе черный (с сахаром и молоком)', protein: 'Б 0 г', fat: 'Ж 0 г', carbs: 'У 5 г', kal: '32.2 Ккал', wt: '100 мл.'}, 
                {name: 'Мед натуральный', protein: 'Б 0 г', fat: 'Ж 0 г', carbs: 'У 8 г', kal: '32.4 Ккал', wt: '10 г.'}          
            ]
        }
    }; 
    render() {
    return (
        <>
            <Container>
                <div className='container-fluid'>
                    <Row>
                        <Col md={2}>
                            <h3 className='menu_data-title' style={{color: '#7db360'}}>Завтрак</h3>
                            <p className='menu_data-text'>745 дневной нормы Ккал</p>
                            <div>                                
                                { this.state.rpogressrow.map(rpogress => {
                                            return (         
                                                                            
                                            <RationTableProgressCreate                                
                                                rpogress={rpogress} key={rpogress.id}                                                                     
                                            />                                                        
                                            )
                                }) }                                  
                            </div>
                        </Col>
                        <Col md={10}>
                            <Table striped bordered hover className='ration-table-one'>
                                <tbody>
                                        { this.state.tablerow.map(ration => {
                                            return (         
                                                                            
                                            <RationTableCreate                                
                                                ration={ration} key={ration.id}                                                                     
                                            />                                                        
                                            )
                                        }) } 
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>    
            </Container>                       
        </>
    )
  }
}

